<template>
  <div
    class="min-h-full bg-white border-0 border-t border-solid border-gray-300"
  >
    <div class="flex flex-col justify-start items-start py-6">
      <h1 class="font-EffraR text-2xl mb-1">Horaires de travail</h1>
      <h2 class="font-EffraR text-lg">
        Cette configuration sera appliquée sur toutes les semaines à venir
      </h2>
    </div>
    <settings-working-hours :categories="categories"></settings-working-hours>

    <!-- List Motifs -->
    <section class="py-5">
      <!-- Header -->
      <div class="flex flex-col mb-5">
        <h2 class="text-2xl text-black font-EffraR">Motifs</h2>
        <span class="text-base font-EffraR" style="color: #565656"
          >Veuillez configurer votre agenda en ajoutant vos motifs de
          consultation</span
        >
      </div>
      <!-- Motifs -->
      <div
        style="border: solid 2px #f4f4f4"
        class="rounded-lg px-5 grid grid-cols-1 lg:grid-cols-5 gap-4 mb-4"
        v-for="(category, index) in categories"
        :key="index"
        :class="(category.title === 'Téléconsultation' ? 'bg-gray-100' : '')+' '+(category.deactivated ? 'bg-gray-300':'')"

      >
        <div class="flex flex-row items-start gap-x-2 py-2">
          <i class="far fa-file-alt"></i>
          <div class="flex flex-col gap-y-1">
            <span class="font-EffraR text-base" style="color: #767676"
              >Titre</span
            >
            <span class="font-EffraR text-black text-lg">{{
              category.title
            }}</span>
          </div>
        </div>
        <div class="flex flex-row items-start gap-x-2 py-2">
          <i class="far fa-clock"></i>
          <div class="flex flex-col gap-y-1">
            <span class="font-EffraR text-base" style="color: #767676"
              >Durée</span
            >
            <span class="font-EffraR text-black text-lg relative"
              >{{ category.duration }} min
            </span>
          </div>
        </div>
        <div class="flex flex-row items-start gap-x-2 py-2">
          <i class="fas fa-coins"></i>
          <div class="flex flex-col gap-y-1">
            <span class="font-EffraR text-base" style="color: #767676"
              >Prix (DH)</span
            >
            <span class="font-EffraR text-black text-lg">
              <template v-if="category.price === 0">
                  Gratuit
              </template>
              <template v-else-if="!category.price">
                N/A
              </template>
              <template v-else>
                {{category.price}}
              </template>
              </span>
          </div>
        </div>
        <div class="flex flex-row items-start gap-x-2 py-2">
          <i class="fas fa-paint-brush"></i>
          <div class="flex flex-col gap-y-1">
            <span class="font-EffraR text-base" style="color: #767676"
              >Couleur</span
            >
            <span
              class="font-EffraR text-lg border-2 border-solid p-1 rounded-lg"
              :style="
                `border-color:${category.color}; color: ${category.color};`
              "
              >{{ category.title }}</span
            >
          </div>
        </div>
        <div
          class="
            flex flex-row
            gap-x-2
            items-center
            border-0 border-l-0
            lg:border-l-2
            border-solid
            px-2
          "
          style="border-color: #f4f4f4"
        >
          <button
            class="
              border-0
              bg-transparent
              hover:bg-gray-200
              cursor-pointer
              h-12
              w-12
              rounded-full
              flex
              items-center
              justify-center
              button-tooltip
              group
              focus:bg-dokBlue-lighter
            "
            v-if="!category.deactivated"
            @click="onEditCategoryClicked(index)"
            data-tooltip="Modifier"
            aria-label="Modifier"
          >
            <i class="dico edit-2 w-7 h-7"></i>
          </button>
          <button
            class="
              border-0
              bg-transparent
              hover:bg-gray-200
              cursor-pointer
              h-12
              w-12
              rounded-full
              flex
              items-center
              justify-center
              button-tooltip
              group
              focus:bg-dokBlue-lighter
            "
            @click="onDeleteCategoryClicked(index, category._id)"
            data-tooltip="Supprimer"
            aria-label="Supprimer"
            v-if="category.title !== 'Téléconsultation' && !category.isBasic"
          >
            <svg
              class="stroke-current text-red-600 group-focus:text-dokBlue-ultra"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 6h18M8 6V4c0-.53.21-1.04.586-1.414C8.96 2.21 9.47 2 10 2h4c.53 0 1.04.21 1.414.586C15.79 2.96 16 3.47 16 4v2m3 0v14c0 .53-.21 1.04-.586 1.414C18.04 21.79 17.53 22 17 22H7c-.53 0-1.04-.21-1.414-.586C5.21 21.04 5 20.53 5 20V6h14z"
              />
            </svg>
          </button>
          <button
            class="
              border-0
              bg-transparent
              hover:bg-gray-200
              cursor-pointer
              h-12
              w-12
              rounded-full
              flex
              items-center
              justify-center
              button-tooltip
              group
              focus:bg-dokBlue-lighter
            "
            data-tooltip="Désactiver"
            aria-label="Désactiver"
            @click="onDeleteCategoryClicked(index, category._id)"
            v-if=" category.notModifiable && !category.deactivated">

              <i class="dico power w-7 h-7"></i>

          </button>
          <button
            class="
              border-0
              bg-transparent
              hover:bg-gray-200
              cursor-pointer
              h-12
              w-12
              rounded-full
              flex
              items-center
              justify-center
              button-tooltip
              group
              focus:bg-dokBlue-lighter
            "
            data-tooltip="Activer"
            aria-label="Activer"
            v-if=" category.notModifiable && category.deactivated"
            @click="onReactivateCategoryClicked(index, category._id)"
          >
            <i class="dico check-circle w-7 h-7"></i>
          </button>
          </button>
        </div>
      </div>
      <!-- Button -->
      <button
        style="background: #f4f4f4"
        class="
          rounded-lg
          py-3
          w-210
          flex
          items-center
          border-0
          justify-center
          text-lg
          font-EffraR
          gap-x-2
          cursor-pointer
        "
        @click="onNewMotifClicked"
        aria-label="Ajouter Motif"
      >
        <img src="/svg/plus.svg" alt="Ajouter Motif" />
        Ajouter Motif
      </button>
    </section>

    <!-- Create & Update motif -->
    <modal
      :is-active="isModalActive"
      :exit-modal="
        () => {
          this.isModalActive = false;
        }
      "
      :width="400"
    >
      <form v-on:submit.prevent="onSaveCategoryClicked" class="py-5">
        <h2 class="font-EffraR text-black text-2xl px-5">Motif</h2>
        <div class="pt-4 pb-5 px-5">
          <label for="titre" class="font-EffraR text-base text-gray-500"
            >Titre</label
          >
          <input
            placeholder="Titre"
            class="
              py-3
              px-2
              rounded
              border-2 border-solid
              flex
              items-center
              text-black
              font-EffraR
              text-lg
              w-full
            "
            :class="
              category.title === 'Téléconsultation' && 'cursor-not-allowed'
            "
            style="border-color: #aaaaaa"
            v-model="category.title"
            :disabled="category.title === 'Téléconsultation'"
          />
        </div>
        <div class="flex flex-row items-start gap-x-2 py-2 px-5">
          <i class="far fa-clock"></i>
          <div class="flex flex-col gap-y-1">
            <span class="font-EffraR text-base" style="color: #767676"
              >Durée</span
            >
            <span class="font-EffraR text-black text-lg relative">
              <select
                v-model="category.duration"
                class="
                  absolute
                  top-0
                  w-full
                  bg-white
                  border border-gray-400
                  text-gray-700
                  rounded-cu
                  leading-tight
                  focus:outline-none
                  focus:bg-white
                  focus:border-gray-500
                  text-base
                  appearance-none
                  opacity-0
                "
              >
                <option
                  v-for="(mintue, i) in durationOptions"
                  :value="mintue"
                  :key="i"
                >
                  {{ mintue }} minutes
                </option></select
              >{{ category.duration ? `${category.duration} min` : `--` }}</span
            >
          </div>
        </div>
        <div
          class="
            flex flex-row
            items-start
            gap-x-2
            py-2
            border-0 border-b border-solid border-gray-300
            px-5
          "
        >
          <i class="fas fa-coins"></i>
          <div class="flex flex-col gap-y-1 w-full">
            <span class="font-EffraR text-base" style="color: #767676"
              >Prix en dirhams</span
            >
            <div class="flex flex-row w-full">
              <input
                type="number"
                class="
                  flex-1
                  font-EffraR
                  text-black text-lg
                  border-0
                  bg-transparent
                  focus:outline-none
                  focus:text-dokBlue-ultra
                  focus:placeholder-dokBlue-ultra
                "
                :placeholder="category.price === 0 ? 'Gratuit' : category.price === null ? 'N/A' : category.price"
                v-model="category.price"
                step="1"
                min="0"
              />
              <!-- N/A CHECKBOX -->
              <div class="flex flex-col items-start gap-y-[1px]">
                <label class="text-sm font-EffraR text-gray-400" for="naPrice">Masquer le prix: </label>
                <input id="naPrice" type="checkbox" :checked="category.naMode === 'N/A' ? true : false"  @click="onNAPrice" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="border-0 border-b border-solid border-gray-300 py-5 px-5 mb-5"
        >
          <h2 class="font-EffraR text-black text-xl mb-5">Couleur</h2>
          <div class="flex flex-col max-h-48 overflow-y-scroll px-2 gap-y-2">
            <div
              class="
                flex flex-row
                gap-x-2
                items-center
                font-EffraM
                border border-solid
                py-2
                px-2
                rounded
                max-w-200
              "
              v-for="color in categoriesColor"
              :key="color"
              :style="`color: ${color}`"
            >
              <input
                type="radio"
                class="
                  form-radio
                  text-xl text-dokBlue-ultra
                  border-2 border-solid border-dokBlue-ultra
                "
                :value="color"
                v-model="category.color"
              />
              <span>{{ category.title || "--" }}</span>
            </div>
          </div>
        </div>
        <div class="container mx-auto px-2 flex flex-col gap-y-2">
          <button
            type="submit"
            class="
              bg-dokBlue-ultra
              h-12
              w-full
              text-white text-lg
              font-EffraM
              border-0
              rounded
              cursor-pointer
              flex
              items-center
              justify-center
              gap-x-2
            "
            :aria-label="
              category._id
                ? 'Enregistrer les modifications'
                : 'Ajouter un motif'
            "
          >
            {{
              category._id
                ? "Enregistrer les modifications"
                : "Ajouter un motif"
            }}
          </button>
          <button
            type="button"
            class="
              bg-transparent
              h-12
              w-full
              text-lg
              font-EffraM
              border-0
              rounded
              cursor-pointer
              flex
              items-center
              justify-center
              gap-x-2
            "
            style="color: #e14b57"
            v-if="category._id && category.title !== 'Téléconsultation'"
            @click="onDeleteCategoryClicked(null, category._id)"
            aria-label="Supprimer Motif"
          >
            Supprimer Motif
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<style scoped>
.button-tooltip {
  position: relative;
}

.button-tooltip::after {
  content: attr(data-tooltip);
  background: #383838;
  border-radius: 4px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  color: white;
  height: 34px;
  bottom: -45px;
}

.button-tooltip::after,
.button-tooltip::before {
  position: absolute;
  display: none;
}

.button-tooltip:hover::after,
.button-tooltip:hover::before {
  display: block;
  @apply flex items-center justify-center font-EffraR px-5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus";
import VSelect from "vue-select";
const SettingsWorkingHours = () => import("./components/SettingsWorkingHours");
const VoerroTagsInput = () => import("@voerro/vue-tagsinput");
const modal = () => import("@/views/global-components/dragModal.vue");

export default {
  data() {
    return {
      settings: {},
      weekdays: [],
      categoriesColor: [
        "#ff4183",
        "#00c36b",
        "#7d05c5",
        "#937465",
        "#ff6c00",
        "#6d92c7",
        "#a8c7e8",
        "#43a794",
        "#63bd9a",
        "#f10718",
        "#dd6b7f",
        "#f28d78",
        "#8b4088",
        "#939599",
      ],
      motifsTags: [],
      category: {
        title: "",
        color: "#ff4183",
        reasons: [],
        motifs: [],
        naMode: false,
      },
      isModalActive: false,
      isCategoriesLoading: true,
      isReasonsLoading: true,
      isCategoryFormVisible: false,
      isMotifFormVisible: false,
      isColorsPickerVisible: false,
      durationOptions: [15, 30, 45, 60],
      errorAddMotif: false,
    };
  },
  mounted() {
    // Listner modal
    EventBus.$on("Click_new_motif", () => this.onNewMotifClicked());

    this.isCategoriesLoading = true;
    this.getCategories({
      onData: () => {
        this.isCategoriesLoading = false;
      },
    });
    this.isReasonsLoading = true;
  },
  computed: {
    ...mapGetters({
      categoriesx: "agenda/categories",
      slots: "agenda/slots",
    }),
    categories: {
      get() {
        return this.categoriesx;
      },
      set(newArray) {
        return this.$store.commit("agenda/SET_CATEGORIES", newArray);
      },
    },
  },
  methods: {
    ...mapActions({
      getCategories: "agenda/GET_DOCTOR_CATEGORIES",
      updateCategory: "agenda/UPDATE_CATEGORY",
      // getReasons: "agenda/GET_REASONS",
      // updateReason: "agenda/UPDATE_REASON",
      deleteCategory: "agenda/DELETE_CATEGORY",
      // deleteReason: "agenda/DELETE_REASON"
      reactivateCategory: "agenda/REACTIVATE_CATEGORY",
    }),
    onData(data) {
      if (data) {
        this.weekdays = data.workingDays;
      }
    },
    onEditCategoryClicked(index) {
      if (!this.categories[index]) return console.log("ERROR NOT FOUND");

      // CHECK TYPE PRICE
      let checkPrice =
        this.categories[index].price === 0
          ? "FREE"
          : !this.categories[index].price
          ? "N/A"
          : this.categories[index].price;
      this.category = this.categories[index];
      this.category.naMode = checkPrice;
      // OPEN MODAL
      this.$nextTick(() => {
        this.isModalActive = true;
      });
      this.$performTracking({
        data: {
          id: this.$auth.getUserId(),
          route: "MOTIF",
          action: "EDIT",
          data: {},
        },
      });
    },
    onEditMotifClicked(index) {
      this.isMotifFormVisible = true;
      this.motif = this.reasons[index];
      this.$performTracking({
        data: {
          id: this.$auth.getUserId(),
          route: "MOTIF",
          action: "EDIT",
          data: {},
        },
      });
    },

    onDeleteCategoryClicked(index, categoryId) {
      this.$confirm({
        message: `La suppression ou la désactivation du motif entrainera son retrait  de votre agenda public. Êtes-vous sûrs de vouloir continuer ?`,
        button: {
          no: "Non",
          yes: "Oui",
        },
        callback: (confirm) => {
          var notifMessage = "Suppression réussie";
          if (confirm) {
            this.$performTracking({
              data: {
                id: this.$auth.getUserId(),
                route: "MOTIF",
                action: "DELETE",
                data: {},
              },
            });
            if (index !== null) {
              if (!this.categories[index].notModifiable)
                this.categories.splice(index, 1);
              else {
                notifMessage = "Motif désactivé avec succès";
                this.categories[index].deactivated = true;
              }
            } else {
              this.categories = this.categories.filter((cat) => {
                return cat._id !== categoryId;
              });
            }
            if (categoryId) {
              this.deleteCategory(categoryId);
            }
            this.isModalActive = false;
            EventBus.$emit("flashDok", {
              type: "success",
              message: notifMessage,
            });
            window.location.reload();
          }
        },
      });
    },

    onReactivateCategoryClicked(index, categoryId) {
      this.$confirm({
        message: `Êtes-vous sûr(e) de vouloir reactiver ce motif?`,
        button: {
          no: "Non",
          yes: "Oui",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$performTracking({
              data: {
                id: this.$auth.getUserId(),
                route: "MOTIF",
                action: "UPDATE",
                data: {},
              },
            });
            if (index !== null) {
              this.categories[index].deactivated = false;
            } else {
              this.categories = this.categories.filter((cat) => {
                return cat._id !== categoryId;
              });
            }
            if (categoryId) {
              this.reactivateCategory(categoryId);
            }
            this.isModalActive = false;

            EventBus.$emit("flashDok", {
              type: "success",
              message: "Motif réactivé avec succès",
            });
          }
        },
      });
    },
    onDeleteMotifClicked(index, reasonId) {
      this.reasons.splice(index, 1);
      if (reasonId) {
        this.deleteReason(reasonId);
      }
    },
    onNewMotifClicked() {
      this.category = {
        title: "",
        color: "#ff4183",
        reasons: [],
        motifs: [],
      };
      this.isModalActive = true;
      this.$performTracking({
        data: {
          id: this.$auth.getUserId(),
          route: "MOTIF",
          action: "NEW",
          data: {},
        },
      });
    },
    onSaveCategoryClicked() {
      if (
        !this.category.title ||
        !this.category.color ||
        !this.category.duration
      ) {
        return EventBus.$emit("flashDok", {
          type: "errorr",
          message: "Il y a un problème de traitement des données",
        });
      }
      this.updateCategory({
        data: this.category,
        onData: ({ ok, message }) => {
          if (!ok) {
            return this.$vs.notify({
              time: 4000,
              text: !ok ? message : "",
              color: ok ? "success" : "danger",
              iconPack: "feather",
              icon: "icon-" + (ok ? "check" : "times"),
            });
          }
          this.isCategoriesLoading = true;
          this.getCategories({
            onData: () => {
              this.isCategoriesLoading = false;
            },
          });
          EventBus.$emit("flashDok", {
            type: "success",
            message: "Mise à jour réussie",
          });
          this.category = {
            title: "",
            color: "#ff4183",
            reasons: [],
            motifs: [],
          };
          this.$performTracking({
            data: {
              id: this.$auth.getUserId(),
              route: "MOTIF",
              action: "CREATE",
              data: {},
            },
          });
          this.isModalActive = false;
        },
      });
    },
    onCloseEdit(section) {
      this.$store.dispatch("agenda/GET_CATEGORIES", {});
      if (section === "motif") {
        this.motif = { title: "" };
        this.isMotifFormVisible = false;
      } else {
        this.category = {
          title: "",
          color: "#ff4183",
          reasons: [],
          motifs: [],
        };
        this.isCategoryFormVisible = false;
      }
    },
    onSaveMotifClicked() {
      this.isMotifFormVisible = false;
      if (!this.motif.title) {
        return;
      }
      this.isReasonsLoading = true;
      const thisVue = this;
      this.updateReason({
        data: this.motif,
        onData: () => {
          this.getReasons({
            onData: () => {
              thisVue.isReasonsLoading = false;
              this.$performTracking({
                data: {
                  id: this.$auth.getUserId(),
                  route: "MOTIF",
                  action: "UPDATE",
                  data: {},
                },
              });
            },
          });
        },
      });
      this.motif = { title: "" };
      //this.updateSettings();
    },
    updateSettings() {
      this.$store.dispatch("agenda/UPDATE_SETTINGS", {
        onData: this.onData,
        data: {
          workingDays: this.weekdays,
          categories: this.categories,
          reasons: this.reasons,
        },
      });
    },
    onChangeColorClicked() {
      this.isColorsPickerVisible = true;
    },
    setCategoryColor(color) {
      this.isColorsPickerVisible = false;
      this.category.color = color;
    },
    onNAPrice() {
      if (this.category.naMode === "N/A") {
        this.category.price = 0;
        this.category.naMode = null;
        return;
      }

      this.category.price = null;
      this.category.naMode = "N/A";
    },
  },
  components: {
    VSelect,
    SettingsWorkingHours,
    TagsInput: VoerroTagsInput,
    modal,
  },
};
</script>
